import { PaymentStatus, } from '@/common/interfaces/payment';
import { IDisplayOption } from './../../common/interfaces/product';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    // Product response
    id: '',
    homePage: '',
    amount: 0,
    currency: '',
    evm_networks: [],
    line_items: [],
    solana_network: { name: '', chainId: -1, contract: '', tokens: [] },
    paymentId: '',
    cefi: {
        tokens: [],
    },
    display_option: IDisplayOption.MultiLine,
    // Payment status
    coin: null,
    chainId: -1,
    status: PaymentStatus.PREVIEW,
    statusHistory: [],
    // Special statuses
    isSkipNetworkSelector: true,
    isSkipWalletSelector: false,
    isSkipWalletCoinSelector: false,
    isSkipExchangeCoinSelector: false,
    paymentFlow: 'wallet',
};
export const PaymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // Goes back to the topmost status in history
        previousState: (state, action) => {
            const newHistory = [...state.statusHistory];
            const previousStatus = newHistory.pop() || 0;
            return Object.assign(Object.assign(Object.assign({}, state), action.payload), { status: previousStatus, statusHistory: newHistory });
        },
        // Skips the status in the history
        skipState: (state, action) => {
            return Object.assign(Object.assign({}, state), action.payload);
        },
        // Stores the status in the history
        nextState: (state, action) => {
            return Object.assign(Object.assign(Object.assign({}, state), action.payload), { statusHistory: [...state.statusHistory, state.status] });
        },
        // Update other information (like product response)
        updateState: (state, action) => {
            // Go to the next state
            console.log('ACTION:', action);
            return Object.assign(Object.assign({}, state), action.payload);
        },
        reset: () => initialState,
        resetPaymentState: (state) => {
            const newHistory = [PaymentStatus.PREVIEW];
            return Object.assign(Object.assign({}, state), { coin: null, chainId: -1, status: PaymentStatus.PAYMENT_METHOD, paymentId: '', wallet: undefined, isSkipNetworkSelector: false, isSkipWalletSelector: false, isSkipWalletCoinSelector: false, isSkipExchangeCoinSelector: false, storedPaymentOption: '', statusHistory: newHistory });
        },
        resetToPaymentPreviewPage: (state) => {
            return Object.assign(Object.assign({}, state), { coin: null, chainId: -1, status: PaymentStatus.PREVIEW, paymentId: '', wallet: undefined, isSkipWalletSelector: false, isSkipWalletCoinSelector: false, isSkipExchangeCoinSelector: false, storedPaymentOption: '', statusHistory: [] });
        },
    },
});
// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetPaymentState, resetToPaymentPreviewPage } = PaymentSlice.actions;
export default PaymentSlice.reducer;
