import * as UIReducer from './store/uiSlice';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import ErrorBoundary from './components/general/errorBoundary';
import React, { useEffect } from 'react';
import Router from '@/pages/router';
import styles from './styles.scss';
const App = () => {
    const isMobile = useMediaQuery('(max-width: 800px)'); // We can customize this width
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(UIReducer.updateState({ isMobile }));
    }, [isMobile]);
    return (React.createElement(BrowserRouter, { basename: "/" },
        React.createElement(ErrorBoundary, null,
            React.createElement("div", { className: styles.appContainer },
                React.createElement(Router, null)))));
};
export default App;
