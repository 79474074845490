import { Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';
import loadableComponent from '@/common/layouts/loading';
const payment = loadableComponent(lazy(() => import(/* webpackChunkName: "payment" */ '@/pages/payment/index')));
const noMatch = loadableComponent(lazy(() => import(/* webpackChunkName: "noMatch" */ '@/common/layouts/noMatch/index')));
export const pageRoutes = [
    {
        path: '/:productId',
        component: payment,
    },
    {
        path: '/*',
        component: noMatch,
    },
];
const Router = () => {
    return (React.createElement(Routes, null, pageRoutes.map((item) => (React.createElement(Route, { key: item.path, path: item.path, element: React.createElement(item.component, null) })))));
};
export default Router;
