import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    // Product response
    isMobile: false,
};
export const UISlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        // Update other information (like product response)
        updateState: (state, action) => {
            return Object.assign(Object.assign({}, state), action.payload);
        },
    },
});
// Action creators are generated for each case reducer function
export const { updateState } = UISlice.actions;
export default UISlice.reducer;
