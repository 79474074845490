export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus[PaymentStatus["PREVIEW"] = 0] = "PREVIEW";
    PaymentStatus[PaymentStatus["PAYMENT_METHOD"] = 1] = "PAYMENT_METHOD";
})(PaymentStatus || (PaymentStatus = {}));
export var WalletPaymentStatus;
(function (WalletPaymentStatus) {
    // Wallet flow
    WalletPaymentStatus[WalletPaymentStatus["WALLET"] = 2] = "WALLET";
    WalletPaymentStatus[WalletPaymentStatus["NETWORK"] = 3] = "NETWORK";
    WalletPaymentStatus[WalletPaymentStatus["COIN"] = 4] = "COIN";
    WalletPaymentStatus[WalletPaymentStatus["PAYMENT_SUMMARY"] = 5] = "PAYMENT_SUMMARY";
    WalletPaymentStatus[WalletPaymentStatus["POLLING"] = 6] = "POLLING";
    WalletPaymentStatus[WalletPaymentStatus["SUCCESS"] = 7] = "SUCCESS";
    WalletPaymentStatus[WalletPaymentStatus["FAIL"] = 8] = "FAIL";
})(WalletPaymentStatus || (WalletPaymentStatus = {}));
export var ExchangePaymentStatus;
(function (ExchangePaymentStatus) {
    ExchangePaymentStatus[ExchangePaymentStatus["COIN"] = 9] = "COIN";
    ExchangePaymentStatus[ExchangePaymentStatus["EXCHANGE"] = 10] = "EXCHANGE";
})(ExchangePaymentStatus || (ExchangePaymentStatus = {}));
export var PaymentErrorType;
(function (PaymentErrorType) {
    PaymentErrorType[PaymentErrorType["INSUFFICIENT_BALANCE"] = 0] = "INSUFFICIENT_BALANCE";
    PaymentErrorType[PaymentErrorType["APPROVAL_FAIL"] = 1] = "APPROVAL_FAIL";
    PaymentErrorType[PaymentErrorType["METAMASK_SELECTED_BUT_NOT_INSTALLED"] = 2] = "METAMASK_SELECTED_BUT_NOT_INSTALLED";
    PaymentErrorType[PaymentErrorType["PHANTOM_SELECTED_BUT_NOT_INSTALLED"] = 3] = "PHANTOM_SELECTED_BUT_NOT_INSTALLED";
    PaymentErrorType[PaymentErrorType["UNKNOWN"] = 4] = "UNKNOWN";
    PaymentErrorType[PaymentErrorType["TIMEOUT"] = 5] = "TIMEOUT";
    PaymentErrorType[PaymentErrorType["DIFFERENT_CHAIN"] = 6] = "DIFFERENT_CHAIN";
})(PaymentErrorType || (PaymentErrorType = {}));
export const PaymentStatusTypeValues = [
    ...Object.values(PaymentStatus),
    ...Object.values(WalletPaymentStatus),
    ...Object.values(ExchangePaymentStatus),
].filter((v) => !isNaN(v));
export var WalletKey;
(function (WalletKey) {
    WalletKey["Metamask"] = "metamask";
    WalletKey["Coinbase"] = "coinbase";
    WalletKey["WalletConnect"] = "walletconnect";
    WalletKey["Phantom"] = "phantom";
})(WalletKey || (WalletKey = {}));
// TODO: as string constants instead of string[].
export const AllWalletKeys = new Set(Object.values(WalletKey));
