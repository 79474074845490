// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:'SF Pro Text', sans-serif}._3wKAPDATy7IcI895irsRu{display:flex;flex:1;flex-direction:column;justify-content:center;align-items:center;width:100%;min-height:400px}\n", "",{"version":3,"sources":["webpack://./src/common/layouts/loading/index.scss"],"names":[],"mappings":"AAIA,KACI,QAAS,CACT,qCAAsC,CACzC,wBAKO,YAAa,CACb,MAAO,CACP,qBAAsB,CACtB,sBAAuB,CACvB,kBAAmB,CACnB,UAAW,CACX,gBAAiB","sourcesContent":["$mobile: 800px;\n$filterBarHeight: 60px;\n$sidebarWidth: 256px;\n\nbody {\n    margin: 0;\n    font-family: 'SF Pro Text', sans-serif;\n}\n\n\n:local {\n    .pageLoading {\n        display: flex;\n        flex: 1;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n        min-height: 400px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLoading": "_3wKAPDATy7IcI895irsRu"
};
export default ___CSS_LOADER_EXPORT___;
