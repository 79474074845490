import React from 'react';
import styles from './index.scss';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        this.setState({ error, errorInfo });
    }
    render() {
        var _a, _b;
        const queryParams = new URLSearchParams(window.location.search);
        const debug = true; // !!queryParams.get('debug'); // TODO: add this back
        console.log('debug', queryParams.get('debug'));
        if (this.state.hasError) {
            return (React.createElement("div", { className: styles.errorBoundary },
                React.createElement("pre", null,
                    React.createElement("p", null, "Something went wrong."),
                    debug && (React.createElement(React.Fragment, null,
                        React.createElement("p", null, (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.stack),
                        React.createElement("p", null, "Component stack:"),
                        React.createElement("p", null, (_b = this.state.errorInfo) === null || _b === void 0 ? void 0 : _b.componentStack))))));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
