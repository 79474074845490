// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:'SF Pro Text', sans-serif}._164gaamJca473XMWSUp3oZ{position:fixed;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,0.85);color:white;font-family:'Courier New', Courier, monospace;padding:10px 32px 10px 32px}\n", "",{"version":3,"sources":["webpack://./src/components/general/errorBoundary/index.scss"],"names":[],"mappings":"AAIA,KACI,QAAS,CACT,qCAAsC,CACzC,yBAIC,cAAe,CACf,MAAO,CACP,KAAM,CACN,UAAW,CACX,WAAY,CACZ,iCAAsC,CACtC,WAAY,CAEZ,6CAA8C,CAC9C,2BAA4B","sourcesContent":["$mobile: 800px;\n$filterBarHeight: 60px;\n$sidebarWidth: 256px;\n\nbody {\n    margin: 0;\n    font-family: 'SF Pro Text', sans-serif;\n}\n\n\n.errorBoundary {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba($color: #000000, $alpha: 0.85);\n  color: white;\n  // white-space: pre-wrap;\n  font-family: 'Courier New', Courier, monospace;\n  padding: 10px 32px 10px 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorBoundary": "_164gaamJca473XMWSUp3oZ"
};
export default ___CSS_LOADER_EXPORT___;
